<template>
  <div>
    <b-card>
      <table-spinner :loading="loading || stateLoading"></table-spinner>
      <b-table
        v-if="!loading && !stateLoading"
        id="system-config"
        class="position-relative"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="setOrdering"
          ></table-header-labels>
        </template>
        <template #cell(actions)="data">
          <b-button
            @click="openEditModal(data.item, $event)"
            variant="outline-primary"
          >
            Edit
          </b-button>
        </template>
      </b-table>
      <b-modal
        ref="edit-config-modal"
        cancel-variant="secondary"
        ok-title="Accept"
        cancel-title="Cancel"
        centered
        title="Edit config"
        @ok="onSaveConfig"
      >
        <b-form-group v-if="selectedConfig" label="Name" label-for="Name">
          <b-form-input id="name" v-model="selectedConfig.name" />
        </b-form-group>
        <b-form-group
          v-if="selectedConfig"
          label="Description"
          label-for="Description"
        >
          <b-form-input id="description" v-model="selectedConfig.description" />
        </b-form-group>
        <b-form-group v-if="selectedConfig" label="Value" label-for="Value">
          <b-form-input id="value" v-model="selectedConfig.value" />
        </b-form-group>
      </b-modal>
      <b-card-footer>
        <b-link class="nav-link" :href="horizonLink" target="_blank">
          Horizon
        </b-link>
        <b-link class="nav-link" :href="telescopeLink" target="_blank">
          Telescope
        </b-link>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BTable,
  BButton,
  BCard,
  BModal,
  BFormGroup,
  BLink,
  BFormInput,
  BCardFooter,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import { mapActions } from "vuex";
import debounce from "lodash.debounce";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";
export default {
  components: {
    TableSpinner,
    BTable,
    BButton,
    BCard,
    BModal,
    BFormGroup,
    BFormInput,
    BLink,
    BCardFooter,
    TableHeaderLabels,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "value", label: "Value" },
        { key: "actions", label: "" },
      ],
      selectedConfig: null,
      loading: false,
    };
  },
  computed: {
    backendDomain() {
      return process.env.VUE_APP_API_URL.replace("/api/", "");
    },
    horizonLink() {
      return `${this.backendDomain}/horizon?token=${useJwt.getToken()}`;
    },
    telescopeLink() {
      return `${this.backendDomain}/telescope?token=${useJwt.getToken()}`;
    },
    filter() {
      return {
        ...this.$route.query,
        sort_by: this.$route.query?.sort_by ?? "name",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    data() {
      return this.$store.getters["adminSystemConfig/getSysConfigData"];
    },
    stateLoading() {
      return this.$store.getters["adminSystemConfig/isLoading"];
    },
  },
  mounted() {
    this.getSysConfig(this.filter).catch(console.error);
  },
  methods: {
    ...mapActions({
      getSysConfig: "adminSystemConfig/getSysConfig",
      putConfig: "adminSystemConfig/putConfig",
    }),
    openEditModal(config) {
      this.selectedConfig = { ...config };
      this.$refs["edit-config-modal"].show();
    },
    setOrdering(filter) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getSysConfig(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
    async onSaveConfig() {
      await this.putConfig(this.selectedConfig);
      this.selectedConfig = null;
      this.onRefreshPage();
    },
  },
};
</script>
